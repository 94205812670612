import { axiosKtb } from "@/plugins/axios";
import {Obtener,Save} from '@/Generico/Conexion';
export class TrozasQuery 
{
    recepcionId?: number;
    haciendId?: number;
    haciendaName?: string;
    fehaTumba?: string;
    t2K?: number;
    volumen?: number;
    numeroDespacho!:string;
    items?: PalletTablon[];
    formatoName?:string;
}
export class PalletTablon 
{
    recepcionId?: number;
    recepcionDetalleId?: number;
    palletId?: string;
    espesor?: string;
    volumen?: number;
    haciendaId?: number;
}

export class PlanificacionRequest 
{
    plantaId?: number;
    turnoId?: number;
    fecha?: string;
    volumenM3?: number;
    volumenBFT?: number;
    volumenProducuccion?: number;
    items?: PlanificacionItemRequest[];
    usuarioId?:string;
    haciendaId?:number;
    constructor(_plantaId?:number,_turnoId?:number,_fecha?:string,_volumenM3?:number,
            _volumenBFT?:number,_volumenProduccion?:number,_usuarioId?:string,
            _haciendaId?:number)
    {
        this.plantaId = _plantaId;
        this.turnoId = _turnoId;
        this.fecha = _fecha;
        this.volumenBFT = _volumenBFT;
        this.volumenM3 = _volumenM3;
        this.volumenProducuccion = _volumenProduccion;
        this.usuarioId = _usuarioId;
        this.haciendaId = _haciendaId;
    }

}

export class PlanificacionItemRequest 
{
    recepcionId?: number;
    recepcionDetalleId?: number;
    constructor(_recepcionId?:number,_recepcionDetalleId?:number)
    {
        this.recepcionId = _recepcionId;
        this.recepcionDetalleId = _recepcionDetalleId;
    }
}

export async function ObtenerTroza(plantaId:number,haciendaId?:number)
{
    return Obtener<TrozasQuery>(`planificacion/obtenertrozas/${plantaId}/${haciendaId}`,axiosKtb);
}

export async function ObtenerTablon(plantaId:number,haciendaId?:number)
{
    return Obtener<TrozasQuery>(`planificacion/obtenertablon/${plantaId}/${haciendaId}`,axiosKtb);
}

export async function GuardarPlanificacion(planificacion:PlanificacionRequest)
{
    return Save<PlanificacionRequest>('planificacion/guardar',planificacion, axiosKtb);
}