








































import {Action, State} from 'vuex-class'
import {Vue,Component} from 'vue-property-decorator'
import { Planta } from '@/entidades/Maestro/Planta';
import {ValidarApertura, TurnoAperturaResponse} from '@/entidades/KTB/Turno/Index';
import {ConsumoRequest, ConsumoResponse,ObtenerConsumos,GuardarConsumo,CerrarConsumo,ObtenerConsumosApertura} from '@/entidades/KTB/Consumo/ConsumoEntity';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import { ObtenerTablon, ObtenerTroza, TrozasQuery } from '@/entidades/KTB/Planificacion/PlanificacionListado';
@Component({
    name: 'ConsumoView',
    components:{
        'ConsumoAdd' :() => import('@/components/KTB/Operacion/Consumo/ConsumoAdd.vue'),
        'tabla' :() => import('@/components/General/TablaGeneralFinal.vue'),
        'consumoDetalle' : () => import('@/components/KTB/Operacion/Consumo/ConsumoDetalleAdd.vue')
        
    }
})
export default class ConsumoView extends Vue
{
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Fecha",sortable:false,value:'fechaString'},
            {text:"Hacienda",sortable:false,value:'haciendaName'},
            {text:"Creo",sortable:true,value:'userNameCreate'},
            {text:"Volumen Trozas",sortable:true,value:'volumenM3'},   
            {text:"Volumen Tablon",sortable:true,value:'volumenBFT'},  
            {text:"Estado",sortable:true,value:'estadoProceso'},
            {text:"Actions",sortable:false,value:'actions'}                 
    ];
    modal:boolean=false;
    componenteName:string ="";
    aperturaValidada:boolean =false;
    openTurno:boolean=false;
    turnoAsignado?:TurnoAperturaResponse;
    listadoConsumo:Array<ConsumoResponse>=[];
    listadoTrozas:Array<TrozasQuery> =[];
    listadoTablon:Array<TrozasQuery> =[];
    consumoTmp?:ConsumoResponse;

    async limpiar()
    {
        this.modal =false;
        this.componenteName = "";
        this.ObtenerConsumosApertura();
       
    }
    async cerrarConsumo(item:ConsumoResponse)
    {
        
        try
        {
            var request = new ConsumoRequest();
            request.consumoId = item.consumoId;
            request.usuarioId = this.usuarioId;
            this.changeLoading(new Loading(true,"Cerrando consumo"));

            var rsp = await CerrarConsumo(request);
            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"success",3000));
                this.limpiar();
            }
            else 
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(eror)
        {
            console.log(eror);
        }
        finally
        {
            this.changeLoading(new Loading(false,"Creando consumo"));
        }
    
    }
    async guardarConsumo(request:ConsumoRequest)
    {
        request.plantaId = this.plantas[0].plantaId;
        request.usuarioId = this.usuarioId;
        request.volumenM3 =0;
        request.volumenBFT =0;
        try
        {
            this.changeLoading(new Loading(true,"Creando consumo"));

            var rsp = await GuardarConsumo(request);
            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"success",3000));
                this.limpiar();
            }
            else 
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(eror)
        {
            console.log(eror);
        }
        finally
        {
            this.changeLoading(new Loading(false,"Creando consumo"));
        }
    }
    async ObtenerConsumosApertura()
    {
        try
        {
            this.changeLoading(new Loading(true,"Obteniendo Consumo"));
            if(this.turnoAsignado != null)
            {
                var response = await ObtenerConsumosApertura(this.turnoAsignado.aperturaTurnoId)
                console.log(response);
                this.listadoConsumo = response.isResult;
            }
            else
            {
                this.changeAlerta(new Alerta("No existe turno abierto",true,"danger",3000));
            }
            
        }
        catch(error)
        {
            console.log(error);
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    async abrilModalDetalle(item:ConsumoResponse)
    {
        try
        {
            this.changeLoading(new Loading(true,"Espere por favor...Obteniendo informacion para la asignacion"));

            this.listadoTrozas = (await ObtenerTroza(this.plantas[0].plantaId,item.haciendaId)).isResult;
            this.listadoTablon = (await ObtenerTablon(this.plantas[0].plantaId,item.haciendaId)).isResult;
            
            if(this.listadoTablon.length == 0 && this.listadoTrozas.length ==0)
            {
                this.changeAlerta(new Alerta("No dispone de Volumen ni trozas ni tablon, para consumir",true,"danger",3000));
                return;
            }
            if(this.listadoTablon.length > 0)
            {
                this.listadoTablon.forEach(x=>
            {
                if(x !== undefined)
                {
                    if(x.volumen !== undefined)
                    x.volumen= Math.round(x.volumen)
                if(x.items !== undefined)
                {
                    x.items.forEach(s=>{
                    if(s.volumen !== undefined)
                        s.volumen = Math.round(s.volumen)
                    })
                }
               
                }
                
            })
            }
            if(this.listadoTrozas.length > 0)
            {
                   this.listadoTrozas.forEach(x=>
            {
                if(x.volumen !== undefined)
                    x.volumen= Math.round(x.volumen)
                
            })
            }
            
            this.consumoTmp = item;
            this.componenteName = "consumoDetalle";
            this.modal = true;
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
        
    }
    abrirModal()
    {
        this.componenteName = "ConsumoAdd";
        this.modal = true;

    }
    get propiedades()
    {
        if(this.componenteName == "ConsumoAdd")
        {
            return {'aperturaTurno' : this.turnoAsignado};
        }
        else if(this.componenteName = "consumoDetalle")
        {
            return {'consumo' : this.consumoTmp, 'trozas' : this.listadoTrozas,
                    'tablon' : this.listadoTablon
                    };
        }
        return "";
    }
    async mounted() 
    {
    var rsp = await ValidarApertura(this.plantas[0].plantaId,1);
       this.aperturaValidada = rsp.isSuccess;
       this.turnoAsignado = rsp.isResult;
    //    this.listadoConsumo = (await ObtenerConsumos(this.plantas[0].plantaId)).isResult;
        await this.ObtenerConsumosApertura();
    }
}
