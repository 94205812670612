
import { axiosKtb } from "@/plugins/axios";
import { Save , Obtener ,ObtenerObject,Editar, SaveList} from "@/Generico/Conexion";
import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
export class ConsumoRequest
 {
    consumoId!:number;
    aperturaTurnoId!: number;
    plantaId!: number;
    haciendaId!: number;
    volumenBFT!: number;
    volumenM3!: number;
    usuarioId!: string;
}

export class ConsumoResponse
 {
    consumoId!: number;
    haciendaId!: number;
    haciendaName!: string;
    volumenBFT!: number;
    volumenM3!: number;
    userNameCreate!: string;
    fechaString!:string;
    estadoProceso!:string;
}
export class ConsumoDetalleAddRequest 
{
    consumoId: number;
    recepcionId: number;
    recepcionDetalleId: number;
    constructor(_consumoId:number,_recepcionId:number,_recepcionDetalleId:number)
    {
        this.consumoId = _consumoId;
        this.recepcionId = _recepcionId;
        this.recepcionDetalleId = _recepcionDetalleId;
    }
}



export async function GuardarConsumo(request:ConsumoRequest)
{
    return Save<ConsumoRequest>('consumo/addconsumo',request,axiosKtb);
}
export async function CerrarConsumo(request:ConsumoRequest)
{
    return Editar<ConsumoRequest>('consumo/closeconsumo',request,axiosKtb);
}
export async function ObtenerConsumoActivo(plantaId:number)
{
    return ObtenerObject<ConsumoResponse>(`consumo/obtenerconsumoactivo/${plantaId}`,axiosKtb);
}

export async function ObtenerConsumos(plantaId:number)
{
    return Obtener<ConsumoResponse>(`consumo/obtenerconsumos/${plantaId}`,axiosKtb);
}

export async function ObtenerConsumosApertura(aperturaId:number)
{
    return Obtener<ConsumoResponse>(`consumo/ObtenerConsumosApertura/${aperturaId}`,axiosKtb);
}

export async function AgregarDetalleConsumo(items:Array<ConsumoDetalleAddRequest>)
{
    return SaveList<ConsumoDetalleAddRequest>('consumo/adddetalle',items,axiosKtb);
}

export async function ObtenerHaciendasDisponibles(plantaId:number)
{
    return Obtener<HaciendaItem>(`consumo/ObtenerHaciendaConsumo/${plantaId}`,axiosKtb);
}